import { Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmailAction from "./EmailAction";
import NotificationAction from "./NotificationAction";
import { ExportSDKApi } from "@unity/components";

const OnchangeSection = ({ data, editSelectedRow, editActionRow, errors, editOnChangeAction, editOnchangeSettings, personnel, templateId, selectedPDF, mailerId }) => {

    const [actionsOption, setActionsOption] = useState("noAction")
    const [templatePDFId, setTemplatePDFId] = useState(false)
    const options = ["setDraft", "setApproved", "setRejected", "sendEmail", "generatePDF", "generatePDFAndEmail", "duplicateform", "setinprogress", "setcomplete", "sendnotification"]

    const displayActionSettings = (type, name) => {
        switch (type) {
            case "sendEmail":
                return (<EmailAction data={data} type={name} editOnchangeSettings={editOnchangeSettings} personnel={personnel} errors={errors} genPDF={false} emailNotifToggle={emailNotifToggle} templateId={templateId}/>)
            case "generatePDFAndEmail":
                return (<EmailAction data={data} type={name} editOnchangeSettings={editOnchangeSettings} personnel={personnel} errors={errors} selectedPDF={selectedPDF} genPDF={true} emailNotifToggle={emailNotifToggle} templateId={templateId}/>)
            case "sendnotification":
                return (<NotificationAction data={data} type={name} editOnchangeSettings={editOnchangeSettings} personnel={personnel} errors={errors}/>)
            case "generatePDF":
                return ( templateId != undefined || templateId != null ? <></> : <p>Please <strong>select</strong> a PDF template</p> )
            default:
                return <p>Please select an action</p>
        }
    }
//templateShow ExportSDKApi
    const actionChange = (info) => {
        const temp = { ...data }

        if (info.target.value == "noAction") {
            temp.onChangeOnce = null
            temp.onChangeAlways = null
        } else if (info.target.value == "onChangeOnce") {
            temp[info.target.value] = { action: null, settings: {} }
            temp["onChangeAlways"] = null
        } else {
            temp[info.target.value] = { action: null, settings: {} }
            temp["onChangeOnce"] = null
        }

        editActionRow(temp)
    }

    const emailNotifToggle = (info, sel, pdf, t) => {
        const temp = { ...data }

        if( mailerId != undefined){
        temp[t].settings["mailerId"] = mailerId.id}
        
        if(info.target.value == "custom"){
            temp[t].settings["notify_customer"] = false
            temp[t].settings["notify_assigned"] = false
        }
        else if(info.target.value == "notify_customer"){
            temp[t].settings[info.target.value] = true
            temp[t].settings["notify_assigned"] = false
        } else{
            temp[t].settings[info.target.value] = true
            temp[t].settings["notify_customer"] = false
        }

        if(sel && pdf){
            temp[t].settings["pdfTemplateId"] = sel.pdfTemplateId
        }
        
        editActionRow(temp)
    }


    const getTemplateId = async () => {
        const res = await ExportSDKApi.templateShow(templateId)
    
        if (res.success) {
            setTemplatePDFId(res.data)
        } else{
            setTemplatePDFId(false)
        }
      };

    useEffect( () => {
        getTemplateId()
        if(data.onChangeAlways != null){
            setActionsOption( "onChangeAlways" )
        } else if(data.onChangeOnce != null){
            setActionsOption( "onChangeOnce" )
        } else{
            setActionsOption("noAction")
        }

    }, [data])

    return (
        <div>
            <Divider />

            <div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Actions</strong></p>
                </div>



                <div className="form-group col-lg-12">
                    <FormControl >
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            //defaultValue={ data.onChangeOnce || data.onChangeAlways || "noAction"}
                            value={actionsOption}
                            name="radio-buttons-group"
                            row
                            onChange={(e) => actionChange(e)}
                        >
                            <FormControlLabel value="noAction" control={<Radio />} label="No Action" />

                            <FormControlLabel value="onChangeOnce" control={<Radio />} label="OnChange Once" style={{ marginLeft: "2rem" }} />

                            <FormControlLabel value="onChangeAlways" control={<Radio />} label="OnChange Always" style={{ marginLeft: "2rem" }} />

                        </RadioGroup>
                    </FormControl>
                </div>

                {data.onChangeOnce != null ?
                    <>
                        <div className="form-group col-lg-12" style={{marginBottom:"2rem"}}>
                            <FormControl error={errors && errors.sbu_id || false} fullWidth>
                                <InputLabel>Format</InputLabel>
                                <Select
                                    label="type"
                                    name="action"
                                    onChange={(evt) => editOnChangeAction(evt, "onChangeOnce")}
                                    value={data.onChangeOnce.action || ""}
                                    size="small"
                                >
                                    {options.map((item, key) => {
                                        return <MenuItem key={key} value={item}>{item}</MenuItem>
                                    })}
                                </Select>
                                {/* <FormHelperText>{props.errors && props.errors.sbu_id || ""}</FormHelperText> */}
                            </FormControl>
                        </div>
                        {
                            data.onChangeOnce.action != null ?
                                displayActionSettings(data.onChangeOnce.action, "onChangeOnce")
                                : <></>
                        }
                    </>
                    : <></>}

                {data.onChangeAlways != null ?
                    <>
                        <div className="form-group col-lg-12">
                            <FormControl error={errors && errors.sbu_id || false} fullWidth>
                                <InputLabel>Format</InputLabel>
                                <Select
                                    label="type"
                                    name="action"
                                    onChange={(evt) => editOnChangeAction(evt, "onChangeAlways")}
                                    value={data.onChangeAlways.action || ""}
                                    size="small"
                                >
                                    {options.map((item, key) => {
                                        return <MenuItem key={key} value={item}>{item}</MenuItem>
                                    })}
                                </Select>
                                {/* <FormHelperText>{props.errors && props.errors.sbu_id || ""}</FormHelperText> */}
                            </FormControl>
                        </div>
                        {
                            data.onChangeAlways.action != null ?
                                displayActionSettings(data.onChangeAlways.action, "onChangeAlways")
                                : <></>
                        }
                    </>
                    : <></>}


            </div>

        </div>
    )
}

export default OnchangeSection