import { CheckBox } from "@mui/icons-material";
import { Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

const StringSecion = ({ data, editSelectedRow, errors, toggleRequired, selectedPDF }) => {

    const formatTypes = { string: "Any", email: "Email", date:"date" } //key is value, value is key :D


    return (
        <div>

            <div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Input Label</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Label"
                        name="label"
                        value={data.label || ""}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                        helperText="This text will be displayed to the user on the document."
                    />
                </div>

            </div>
            <Divider />
            <div className="form-row">
                <div className="form-group col-lg-12">
                <p><strong>Rules</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Letter Limit"
                        name="char_limit"
                        type="number"
                        value={data.char_limit || ""}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                    />
                </div>

                <div className="form-group col-lg-12">
                    <FormControl error={errors && errors.sbu_id || false} fullWidth>
                        <InputLabel>Format</InputLabel>
                        <Select
                            label="Format"
                            name="validate_type"
                            onChange={(evt) => editSelectedRow(evt)}
                            value={data.validate_type || ""}
                            size="small"
                        >
                            {
                                Object.keys(formatTypes).map((item) => { return <MenuItem value={item} key={item} >{formatTypes[item]}</MenuItem> })
                            }
                        </Select>
                        {/* <FormHelperText>{props.errors && props.errors.sbu_id || ""}</FormHelperText> */}
                    </FormControl>
                </div>

            </div>


            <Divider />
            <div className="form-row">

            <div className="form-group col-lg-12">
                                <p><strong>Requirements</strong></p>
                            </div>
                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="required"
                        checked={data.required}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Is required?" />
                </div>

                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="managerOnly"
                        checked={data.managerOnly}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Require Manager" />

                </div>
            </div>
        </div>
    )
}

export default StringSecion