import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EmailApi } from "@unity/components";
//EmailApi
const ButtonEmailAction = ({data, type, editOnchangeSettings, personnel, errors, selectedPDF, genPDF, emailNotifToggle, templateId, mailerId}) => {

    const [notifSelected, setNotifSelected] = useState("")
    const [mailers, setMailers ] = useState([])


    useEffect( () => {
        
        if(data.settings.notify_customer){
            setNotifSelected("notify_customer")
        } else if(data.settings.notify_assigned){
            setNotifSelected("notify_assigned")
        } else {
            setNotifSelected("custom")
        }
    }, [data])


    return(
        <>   
                { genPDF ?
                 templateId != undefined || templateId != null ? <></> : <p>Please <strong>select</strong> a PDF template</p>
                : null
                }
                <div className="form-group col-lg-12">
                    <FormControl >
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            //defaultValue={ data.onChangeOnce || data.onChangeAlways || "noAction"}
                            value={ notifSelected }
                            name="radio-buttons-group"
                            row
                            onChange={(e) => emailNotifToggle(e, selectedPDF, genPDF, type)}
                        >
                            <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                            <FormControlLabel value="notify_customer" control={<Radio />} label="Notify Customer" style={{ marginLeft: "2rem" }}/>
                            <FormControlLabel value="notify_assigned" control={<Radio />} label="Notify Assigned" style={{ marginLeft: "2rem" }} />

                        </RadioGroup>
                    </FormControl>
                </div>

                <div className="form-group col-lg-12">
            <TextField
                style={{ width: "100%" }}
                label="To"
                name="to"
                value={data.settings["to"] || ""}
                size="small"
                onChange={(evt) => editOnchangeSettings(evt, type)}
                disabled={data.settings.notify_assigned || data.settings.notify_customer}
                helperText={data.settings.notify_assigned || data.settings.notify_customer ? "Disabled. Please select a sender on the forms page" : null}
            />
        </div>

        <div className="form-group col-lg-12">
            <TextField
                style={{ width: "100%" }}
                label="Subject"
                name="subject"
                value={data.settings["subject"] || ""}
                size="small"
                onChange={(evt) => editOnchangeSettings(evt, type)}
            />
        </div>

        <div className="form-group col-lg-12">
            <TextField
                style={{ width: "100%" }}
                label="Body"
                name="body"
                value={data.settings["body"] || ""}
                size="small"
                onChange={(evt) => editOnchangeSettings(evt, type)}
            />
        </div>
        
    </>
    )
}

export default ButtonEmailAction