import { CheckBox } from "@mui/icons-material";
import { Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { MAX_NUMBER, MIN_NUMBER } from "../../common/ValidateStringBuilder";

const NumberSection = ({ data, editSelectedRow, errors, toggleRequired, selectedPDF }) => {


    const formatTypes = { string: "Any", email: "Email" } //key is value, value is key :D

    return (
        <div>

<div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Input Label</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Label"
                        name="label"
                        value={data.label || ""}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                        helperText="This text will be displayed to the user on the document."
                    />
                </div>

            </div>
            <Divider />
            <div className="form-row">
                <div className="form-group col-lg-12">
                <p><strong>Rules</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Minimum"
                        name="min"
                        type="number"
                        value={data.min || MIN_NUMBER}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                    />
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Maximum"
                        name="max"
                        type="number"
                        value={data.max || MAX_NUMBER}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                    />
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Default Number"
                        name="default"
                        type="number"
                        value={data.default || MIN_NUMBER}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                    />
                </div>

            </div>


            <Divider />
            <div className="form-row">

            <div className="form-group col-lg-12">
                                <p><strong>Requirements</strong></p>
                            </div>
                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="required"
                        checked={data.required}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Is required?" />
                </div>

                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="managerOnly"
                        checked={data.managerOnly}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Require Manager" />

                </div>
            </div>
        </div>
    )
}

export default NumberSection