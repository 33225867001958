import { CheckBox } from "@mui/icons-material";
import { Checkbox, Divider, FormControl, FormControlLabel, TextField } from "@mui/material";
import React from "react";

const FixedTextSection = ({ data, editSelectedRow, errors, toggleRequired, selectedPDF }) => {


    return (
        <div>

            <div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Input Label</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Label"
                        name="label"
                        value={data.label || ""}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                        helperText="This text will be displayed to the user on the document."
                    />
                </div>

            </div>
            <Divider />
            <div className="form-row">
                <div className="form-group col-lg-12">
                <p><strong>Rules</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <FormControlLabel control={<Checkbox
                        name="header"
                        checked={data.header}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="If you would like the text to be displayed as a header, please check the box." />
                </div>

            </div>


            <Divider />
            <div className="form-row">

            <div className="form-group col-lg-12">
                                <p><strong>Requirements</strong></p>
                            </div>
                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="required"
                        checked={data.required}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Is required?" />
                </div>

                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="managerOnly"
                        checked={data.managerOnly}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Require Manager" />

                </div>
            </div>
        </div>
    )
}

export default FixedTextSection