import { CheckBox } from "@mui/icons-material";
import { Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import OnchangeSection from "./common/OnchangeSection";

const CheckboxSection = ({ data, editSelectedRow, errors, toggleRequired, editActionRow, editOnChangeAction, editOnchangeSettings, personnel, templateId, selectedPDF, mailerId }) => {

    useEffect(
        () => {
            editSelectedRow({ target: { name: "checkbox_value", value: false } })
        },[data]
    )

    return (
        <div>

<div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Input Label</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Label"
                        name="label"
                        value={data.label || ""}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                        helperText="This text will be displayed to the user on the document."
                    />
                </div>

            </div>
            <Divider />

            <div className="form-row">

            <div className="form-group col-lg-12">
                                <p><strong>Requirements</strong></p>
                            </div>
                            
                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="required"
                        checked={data.required}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Is required?" />
                </div>

                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="managerOnly"
                        checked={data.managerOnly}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Require Manager" />

                </div>
            </div>

            <OnchangeSection 
                data={data} 
                editSelectedRow={editSelectedRow} 
                editActionRow={editActionRow} 
                errors={errors}
                editOnChangeAction={editOnChangeAction} 
                editOnchangeSettings={editOnchangeSettings}
                personnel={personnel} 
                templateId={templateId}
                selectedPDF={selectedPDF}
                mailerId={mailerId}
                />
        </div>
    )
}

export default CheckboxSection