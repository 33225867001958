import { Button, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, toggleRequired, selectedRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonActionsSection from "./common/ButtonActionsSection";

const ButtonSection = ({ data, editSelectedRow, errors, toggleRequired, context, editActionRow, editOnChangeAction, editOnchangeSettings, personnel, templateId, selectedPDF, editButtonChangeAction, editButtonchangeSettings, mailerId }) => {

    return (
        <div>
            <div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Button Label</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Button Text"
                        name="label"
                        value={data.label || ""}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                    />
                </div>

            </div>

            <Divider />

            <div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Requirements</strong></p>
                </div>


                <div className="form-group col-lg-12">
                    <FormControlLabel control={<Checkbox
                        name="managerOnly"
                        checked={data.managerOnly}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Require Manager" />

                </div>
            </div>
            <ButtonActionsSection
                data={data}
                editSelectedRow={editSelectedRow}
                editActionRow={editActionRow}
                errors={errors}
                editOnChangeAction={editButtonChangeAction}
                editOnchangeSettings={editButtonchangeSettings}
                personnel={personnel}
                templateId={templateId}
                selectedPDF={selectedPDF}
                isButton={true}
                mailerId={mailerId}
            />


        </div>
    )
}

export default ButtonSection