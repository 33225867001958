import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

const NotificationAction = ({ data, type, editOnchangeSettings, personnel, errors }) => {

    const toggleAssigned = (info, toggle) => {
        editOnchangeSettings({target:{name: "notify_assigned" , value: toggle}} , type)
    }

    return (

        <>

            <div className="form-group-sm col-lg-6" style={{marginBottom: "1rem"}} >
                            {personnel ? 
                            <FormControl error={errors && props.errors.sbu_id || false} fullWidth disabled={data[type].settings.notify_assigned}>
                            {/* <FormControl error={props.errors && props.errors.sbu_id || false} fullWidth></FormControl> */}
                                <InputLabel>Personnel</InputLabel>
                                <Select
                                    label="Person Assigned"
                                    name="user_uuid"
                                    value={data[type].settings.user_uuid || ""}
                                    size="small"
                                    onChange={(evt) => editOnchangeSettings(evt, type)}
                                >
                                    {personnel && personnel.map((item, key) => {
                                        return <MenuItem key={key} value={item.uuid}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{errors && errors.sbu_id || ""}</FormHelperText>
                            </FormControl> :
                            <CircularProgress /> 
                            }
                        </div>

                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="required"
                        checked={data[type].settings.notify_assigned}
                        onChange={(evt, checked) => toggleAssigned(evt, checked)}
                    />} label="Notify Assigned" />

                </div>

            <div className="form-group col-lg-12">
                <TextField
                    style={{ width: "100%" }}
                    label="Name"
                    name="name"
                    value={data[type].settings["name"] || ""}
                    size="small"
                    onChange={(evt) => editOnchangeSettings(evt, type)}
                />
            </div>

            <div className="form-group col-lg-12">
                <TextField
                    style={{ width: "100%" }}
                    label="Description"
                    name="description"
                    value={data[type].settings["description"] || ""}
                    size="small"
                    onChange={(evt) => editOnchangeSettings(evt, type)}
                />
            </div>

            <div className="form-group col-lg-12">
                <TextField
                    style={{ width: "100%" }}
                    label="Link Name"
                    name="link"
                    value={data[type].settings["link"] || ""}
                    size="small"
                    onChange={(evt) => editOnchangeSettings(evt, type)}
                />
            </div>

            <div className="form-group col-lg-12">
                <TextField
                    style={{ width: "100%" }}
                    label="Link URL"
                    name="link_action"
                    value={data[type].settings["link_action"] || ""}
                    size="small"
                    onChange={(evt) => editOnchangeSettings(evt, type)}
                />
            </div>
        </>

    )
}

export default NotificationAction