//React imports
import React from "react";

//3rd party libary imports
import { DataGrid, GridToolbarDensitySelector, GridToolbarFilterButton } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";


function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <IconButton
          title="Delete"
          aria-label="Delete"
          size="small"
          style={{ visibility: props.delete ? "visible" : "hidden" }}
          onClick={props.executeDelete}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}


/*
 * DataTable using MUI dataGrid.
 * @param columns - REQURIED the name of the columns.
 * @param data - REQURIED the data that will be used in the table.
 * @param pagination - OPTIONAL boolean state if the index will be server side paginated.
 * @param options - OPTIONAL other options for the table see MUI DataGrid API for help.
 * @param allowDelete - REQUIRED boolean state for if user has the delete permission.
 * @param handleDeleteArray - REQUIRED function to delete an array of id's to delete OR false boolean flag if delete row is not available on this index!
 * @param allowDelete - REQUIRED boolean flag set from the users access permissions.
 * @param handleDeleteArray - OPTIONAL function to take an array of id's of items to call delete on.
 * @param searchText - REQUIRED the string state of the search term entered by the user.
 * @param setSearchText - REQUIRED function to set the state of searchText.
 * @param fetchTrigger - REQUIRED the integer state used to trigger a re-render. (Just increment it if you do!).
 * @param setFetchTrigger - REQUIRED the function used to set the fetchTrigger state.
*/

/* Options Object
 * All are requried if pag is true.
 * @param page - The current page number
 * @param pageSize - The requested number of items on the page.
 * @param rowCount - Total number of rows returned on the page.
 * @param rowsPerPageOptions - [25, 50, 100] Array of available options for page size (ie the pageSize param).
 * @param handlePageChange - function to change the page param. Takes (page) which is the page number.
 * @param handleSizeChange - function to change the pageSize param. Takes (size) which is the pageSize.
 */

/// ********* EXAMPLE OPTIONS OBJECT ********** ///
// const options = {
//   pageSize: 15,
//   rowCount: rowsTotal,
//   rowsPerPageOptions: [10, 25, 100],
//   handlePageChange: handlePageChange,
//   autoHeight: false,
//   height: "350",
// };

export default function DataTable({ columns, data, pagination, options, allowDelete, handleDeleteArray, searchText, setSearchText, fetchTrigger, setFetchTrigger, getRowId }) {
  const [rows, setRows] = React.useState(data);
  const [selectionModel, setSelectionModel] = React.useState([]);
  console.log("options",options)

  // If data is server side paginated then will need a different approach to the search!
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    } else {
      const filteredRows = rows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field]);
        });
      });
      setRows(filteredRows);
    }
  };

  const clearSearchBox = () => {
    setRows(data);
    setSearchText('');

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleRowsDelete = async (array) => {
    await handleDeleteArray(array);
    setSelectionModel([]);
    setRows(data);
    setSearchText('');
  };


  React.useEffect(() => {
    setRows(data);
  }, [data]);


  if (pagination) {
    return (
      <>
        <div style={{ width: "100%", height: options.height, marginTop: "5rem" }}>
          <DataGrid
            autoHeight={options.autoHeight}
            columns={columns}
            rows={rows}
            getRowId={getRowId ? getRowId : null}
            checkboxSelection={handleDeleteArray ? true : false}
            disableSelectionOnClick={true}
            paginationMode="server"
            page={options.page}
            pageSize={options.pageSize}
            rowCount={options.rowCount}
            onPageSizeChange={(size) => options.handleSizeChange(size)}
            onPageChange={(page) => options.handlePageChange(page)}
            rowsPerPageOptions={options.rowsPerPageOptions}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => clearSearchBox(),
                executeDelete: () => handleRowsDelete(selectionModel),
                delete: handleDeleteArray && allowDelete
              }
            }}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
          />
        </div>
      </>
    );
  } else
    return (
      <>
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            columns={columns}
            rows={rows}
            checkboxSelection={handleDeleteArray ? true : false}
            disableSelectionOnClick={true}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => clearSearchBox(),
                executeDelete: () => handleRowsDelete(selectionModel),
                delete: handleDeleteArray && allowDelete
              }
            }}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
          />
        </div>
      </>
    );
}
