import { CheckBox } from "@mui/icons-material";
import { Button, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, toggleRequired, selectedRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModuleButton from "../../common/ModuleButton";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const OptionsSecion = ({ data, editSelectedRow, errors, toggleRequired, selectedPDF }) => {

    const [optionsArray, setOptionsArray] = useState([])

    const addNewRow = () => {
        const temp = [...optionsArray];
        const input = document.getElementById("newOptionField").value;
        const input2 = document.getElementById("newOptionValue").value;

        temp.push({ option: input, value: input2, default: false });
        setOptionsArray(temp);
        document.getElementById("newOptionField").value = "";
        document.getElementById("newOptionValue").value = "";

        editSelectedRow({ target: { name: "options", value: temp } })
    }

    const setDefaultOption = (index) => {
        const temp = [...optionsArray];

        temp.map((e, key) => {
            if (e.default && key != index) { e.default = false }
        })

        temp[index].default ?
            temp[index].default = false :
            temp[index].default = true

        editSelectedRow({ target: { name: "default", value: temp } })
    }


    const deleteRow = (i) => {
        const temp = [...optionsArray];
        temp.splice(i, 1)
        setOptionsArray(temp);

        editSelectedRow({ target: { name: "options", value: temp } })
    }


    useEffect(
        () => {
            if (data.options) {
                setOptionsArray(data.options)
            } else {
                setOptionsArray([])
            }
        }, [data]
    )


    return (
        <div>

<div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Input Label</strong></p>
                </div>

                <div className="form-group col-lg-12">
                    <TextField
                        style={{ width: "100%" }}
                        label="Label"
                        name="label"
                        value={data.label || ""}
                        size="small"
                        onChange={(evt) => editSelectedRow(evt)}
                        helperText="This text will be displayed to the user on the document."
                    />
                </div>

            </div>
            <Divider />

            <div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Rules</strong></p>
                </div>

                <div className="form-group col-lg-5">
                    <TextField
                        style={{ width: "100%" }}
                        label="Option Text"
                        size="small"
                        id="newOptionField"
                    />
                </div>

                <div className="form-group col-lg-5">
                    <TextField
                        style={{ width: "100%" }}
                        label="Value"
                        size="small"
                        id="newOptionValue"
                    />
                </div>

                <div className="form-group col-lg-2">
                    <Button
                        variant="contained"
                        component="label"
                        style={{ backgroundColor: "orange", marginLeft: "10px" }}
                        onClick={() => addNewRow()}
                    >
                        <AddIcon />
                    </Button>
                </div>

                {data.options && data.options.length > 0 ?

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Option</strong></TableCell>
                                    <TableCell><strong>Value</strong></TableCell>
                                    <TableCell align="center"><strong>Action</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {data.options && data.options.map((row, key) => (

                                    <TableRow
                                        //key={row.description}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.option}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row.value}
                                        </TableCell>


                                        <TableCell align='center' style={{ width: "360px" }}>

                                            {row.default ?
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ backgroundColor: "orange", marginLeft: "10px" }}
                                                    onClick={() => setDefaultOption(key)}
                                                >
                                                    <StarIcon />
                                                </Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ backgroundColor: "orange", marginLeft: "10px" }}
                                                    onClick={() => setDefaultOption(key)}
                                                >
                                                    <StarBorderIcon />
                                                </Button>}

                                            <Button
                                                variant="contained"
                                                component="label"
                                                style={{ backgroundColor: "red", marginLeft: "10px" }}
                                                onClick={() => deleteRow(key)}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    : null
                }


            </div>


            <Divider />
            <div className="form-row">

            <div className="form-group col-lg-12">
                                <p><strong>Requirements</strong></p>
                            </div>
                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="required"
                        checked={data.required}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Is required?" />
                </div>

                <div className="form-group col-lg-6">
                    <FormControlLabel control={<Checkbox
                        name="managerOnly"
                        checked={data.managerOnly}
                        onChange={(evt, checked) => toggleRequired(evt, checked)}
                    />} label="Require Manager" />

                </div>
            </div>
        </div>
    )
}

export default OptionsSecion