import React, { useState, useEffect, Suspense } from "react";
import { EntityFormApi } from "@unity/components";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Fade, IconButton, LinearProgress, Typography, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

const ReferenceContent = React.lazy(() => import("./referenceContent"));

export default function ReferenceButton({context, setReferenceId, referenceId}) {
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadReference = async () => {
        try {
            const res = await EntityFormApi.counterShow(referenceId);
            if(!res.ok || !res.data) throw "Call Failed";

            setCounter(res.data);
        } catch (e) {
            console.error("Failed to load counter", e);
            alert("Failed to load counter");
        }
    }

    const loadData = async () => {
        setLoading(true);
        setCounter(false);
        await loadReference();
        setLoading(false);
    }

    useEffect(() => {
        if(referenceId) loadData();
    }, [referenceId]);

    if(loading) return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", width: "95vw", gap: "1vh", textAlign: "center"}}>
            <CircularProgress/>
            <Typography variant="body1">Loading Reference Generator...</Typography>
        </div>
    )

    const ReferenceDialog = () => (
        <Dialog
            open={open}
            onClose={() => {setOpen(false)}}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle
                style={{ backgroundColor: context.theme.top_menu.background, color: context.theme.top_menu.font, marginBottom: "1vh" }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h5">Reference Manager</Typography>
                    <IconButton
                        onClick={() => {setOpen(false)}}
                        style={{ color: context.theme.top_menu.font }}
                    >
                        <Close/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Suspense fallback={(<Fade in={true}><LinearProgress/></Fade>)}>
                    <ReferenceContent
                        referenceId={referenceId}
                        setReferenceId={setReferenceId}
                        context={context}
                        counter={counter}
                    />
                </Suspense>
            </DialogContent>
        </Dialog>
    )

    if(referenceId && counter) return (
        <>
        <Button
            variant="contained"
            style={{ backgroundColor: context.theme.top_menu.background, color: context.theme.top_menu.font }}
            onClick={() => {setOpen(true)}}
        >
            Manage {counter.name} Reference
        </Button>
        <ReferenceDialog/>
        </>
    )

    return (
        <>
            <Button
                variant="contained"
                style={{ backgroundColor: context.theme.top_menu.background, color: context.theme.top_menu.font }}
                onClick={() => {setOpen(true)}}
            >
                Select Reference Generator
            </Button>
            <ReferenceDialog/>
        </>
    )
} 