import { Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmailAction from "./EmailAction";
import NotificationAction from "./NotificationAction";
import { ExportSDKApi } from "@unity/components";
import ButtonEmailAction from "./ButtonEmailAction";
import ButtonNotificationAction from "./ButtonNotificationAction";

const ButtonActionsSection = ({ data, editSelectedRow, editActionRow, errors, editOnChangeAction, editOnchangeSettings, personnel, templateId, selectedPDF, mailerId }) => {

    const [actionsOption, setActionsOption] = useState("noAction")
    const [templatePDFId, setTemplatePDFId] = useState(false)
    const options = ["setDraft", "setApproved", "setRejected", "sendEmail", "generatePDF", "generatePDFAndEmail", "duplicateform", "setinprogress", "setcomplete", "sendnotification"]

    const displayActionSettings = (type, name) => {
        switch (type) {
            case "sendEmail":
                return (<ButtonEmailAction data={data} type={name} editOnchangeSettings={editOnchangeSettings} personnel={personnel} errors={errors} genPDF={false} emailNotifToggle={emailNotifToggle} mailerId={mailerId} templateId={templateId}/>)
            case "generatePDFAndEmail":
                return (<ButtonEmailAction data={data} type={name} editOnchangeSettings={editOnchangeSettings} personnel={personnel} errors={errors} selectedPDF={selectedPDF} genPDF={true} emailNotifToggle={emailNotifToggle} mailerId={mailerId} templateId={templateId}/>)
            case "sendnotification":
                return (<ButtonNotificationAction data={data} type={name} editOnchangeSettings={editOnchangeSettings} personnel={personnel} errors={errors}/>)
            case "generatePDF":
                return ( templateId != undefined || templateId != null ? <></> : <p>Please <strong>select</strong> a PDF template</p> )
            default:
                return <p>Please select an action</p>
        }
    }

    const emailNotifToggle = (info, sel, pdf, t) => {
        const temp = { ...data }
        
        if( mailerId != undefined){
        temp.settings["mailerId"] = mailerId.id}

        if(info.target.value == "custom"){
            temp.settings["notify_customer"] = false
            temp.settings["notify_assigned"] = false
        }
        else if(info.target.value == "notify_customer"){
            temp.settings[info.target.value] = true
            temp.settings["notify_assigned"] = false
        } else{
            temp.settings[info.target.value] = true
            temp.settings["notify_customer"] = false
        }

        if(sel && pdf){
            temp.settings["pdfTemplateId"] = sel.pdfTemplateId
        }
        
        editActionRow(temp)
    }


    const getTemplateId = async () => {
        const res = await ExportSDKApi.templateShow(templateId)
    
        if (res.success) {
            setTemplatePDFId(res.data)
        } else{
            setTemplatePDFId(false)
        }
      };

    useEffect( () => {
        getTemplateId()
    }, [data])

    return (
        <div>
            <Divider />

            <div className="form-row">
                <div className="form-group col-lg-12">
                    <p><strong>Actions</strong></p>
                </div>


              
                        <div className="form-group col-lg-12" style={{marginBottom:"2rem"}}>
                            <FormControl error={errors && errors.sbu_id || false} fullWidth>
                                <InputLabel>Format</InputLabel>
                                <Select
                                    label="type"
                                    name="action"
                                    onChange={(evt) => editOnChangeAction(evt)}
                                    value={data.action || ""}
                                    size="small"
                                >
                                    {options.map((item, key) => {
                                        return <MenuItem key={key} value={item}>{item}</MenuItem>
                                    })}
                                </Select>
                                {/* <FormHelperText>{props.errors && props.errors.sbu_id || ""}</FormHelperText> */}
                            </FormControl>
                        </div>
                        {
                            data.action != null ?
                                displayActionSettings(data.action)
                                : <></>
                        }
        
                

                


            </div>

        </div>
    )
}

export default ButtonActionsSection