import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { DealBuilderApi, ApiLoaderComponent, LoadingComponent, AppHeaderComponent, EntityFormApi } from "@unity/components";
import { CanDelete, CanReadAll, CanUpdate } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Tooltip } from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function ActiveIndex({ context, bus }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [personnel, setPersonnel] = useState(false)

  let permissions = context.auth.access["form-templates"]

  const edit = CanUpdate("form-templates");
  const readAll = CanReadAll("form-templates");

  const history = useHistory();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/form-templates/${path}/${params._id}`,
      state: { ...params }
    });
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };


  const getDraftForms = async () => {

    const res = await EntityFormApi.templateIndex(page + 1, pageSize, { approved: true });

    if (res.ok) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  //---------------------------------------snakbar

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
 
    const [open, setOpen] = React.useState(false);
  
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

  //----------------------------------------------


  //delete payload._type_id handleDuplicateEdit

  const handleDuplicateEdit = async (obj) => {

    const tempObj = {...obj}
    const tempId = tempObj._id

    tempObj["archiveOnUpdate"] = true
    tempObj["approved"] = false 
    tempObj["archived"] = true
    delete tempObj._id

    
    setLoading({ status: true, data: 'Checking Template for approval request, please wait...' });

    const res = await EntityFormApi.templateUpdate(tempId, {template: tempObj});

    if (res.success) {
      setLoading({ status: true, data: "Successfully duplcated form. Please go to the drafts tab to find your template." });
      setOpen(true);
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
        history.push({
          //pathname: `/form-templates/edit/${res.data._id}`,
          state: { hasErrors: res.errors }
        });
      }, 3000);
    } else {
      if (res.errors) {
        setLoading({ status: true, data: 'Validation Errors!, Please Wait....' });
        setTimeout(() => {
          history.push({
            //pathname: `/form-templates/edit/${id}`,
            state: { hasErrors: res.errors }
          });
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      }
    }
    
    // setFetchTrigger(fetchTrigger + 1);
  }

  const handleTemplateArchive = async (obj) => {

    const tempObj = {...obj}
    const tempId = tempObj._id

    tempObj.approved = false
    tempObj["archived"] = true
    delete tempObj._id
    
    setLoading({ status: true, data: 'Checking Template for approval request, please wait...' });

    const res = await EntityFormApi.templateUpdate(tempId, {template: tempObj});

    if (res.success) {
      setLoading({ status: true, data: "Successfully requested Form approval" });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setLoading({ status: true, data: 'Validation Errors!, Please Wait....' });
        setTimeout(() => {
          history.push({
            //pathname: `/form-templates/edit/${id}`,
            state: { hasErrors: res.errors }
          });
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      }
    }
    //setFetchTrigger(fetchTrigger + 1);
  }

  const convertDateToText = (date) => {
    try {
        const d = new Date(date);
        d.setHours(d.getHours() + 1);
        return new Intl.DateTimeFormat('en-GB', { dateStyle: 'long', timeStyle: 'medium', timeZone: 'Europe/London' }).format(d)
    } catch (e) {
        return "Invalid Date";
    }
}


  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "updated_at", headerName: "Last Update", flex: 1, 
    valueGetter: (val) => {
      return(
      convertDateToText(val.row.updated_at)
      )
    }
  },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (

          <div>

            <ModuleButton
              btnIcon="visibility"
              style={{
                background: "blue",
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, { ...cellValues.row, hasErrors: false }, "edit")}
              tip="View"
            />


              {permissions.MA ? 
            <Tooltip title={"Duplicate"}>
              <Button
                variant="contained"
                component="label"
                style={{
                  background: "orange",
                  color: "white",
                  marginLeft: "10px"
                }}
                onClick={() => handleDuplicateEdit( cellValues.row )}
              >
                <ContentCopyIcon />
              </Button>
            </Tooltip>
            : null
            }

            {permissions.MA ? 
                        <Tooltip title={"archive"}>
                        <Button
                          variant="contained"
                          component="label"
                          style={{
                            background: "red",
                            color: "white",
                            marginLeft: "10px"
                          }}
                          onClick={() => handleTemplateArchive( cellValues.row )}
                        >
                          <ArchiveIcon />
                        </Button>
                      </Tooltip>

            : null
          }

          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };


  useEffect(() => {
    getDraftForms();
    ModuleFunctions.getPersonnel({ personnelChange: setPersonnel });
  }, [fetchTrigger]);


  if (permissions.R && data) {
    return (
      <>

        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={CanDelete("form-templates")}
          searchText={searchText}
          getRowId={(data) => data._id}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />

<Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Form duplicated. Please check the draft index
        </Alert>
      </Snackbar>
    </Stack>

        <ApiLoaderComponent status={loading.status} data={loading.data} />


      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
