import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Deals from "./pages/index";
import DealEdit from "./pages/edit";
import FormTemplateCreate from "./pages/create";



export default function Root(props) {

  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} /> 

                    {props.auth.access["form-templates"]["A"] &&
                      props.auth.access["form-templates"]["R"] && (
                        <Route
                          path="/form-templates/index"
                          exact
                          component={(route) => (
                            <Deals
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["form-templates"]["A"] &&
                      props.auth.access["form-templates"]["C"] && (
                        <Route
                          path="/form-templates/create"
                          exact
                          component={(route) => (
                            <FormTemplateCreate
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                      {props.auth.access["form-templates"]["A"] &&
                      props.auth.access["form-templates"]["U"] && (
                        <Route
                          path="/form-templates/edit/:id"
                          exact
                          component={(route) => (
                            <DealEdit
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
