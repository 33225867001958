import React, { useEffect, useState , useRef } from "react";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, TextField, InputAdornment, CircularProgress, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import ModuleFunctions from "./ModuleFunctions";
import { PersonnelApi } from '@unity/components';
import SaveIcon from '@mui/icons-material/Save';


export default function FormCreate(props) {
    const [loading, setLoading] = useState(false);

    const handleChange = (newValue) => {
        let a = new Object
        a[newValue.target.name] = newValue.target.value;
        props.formChange( {...a , changed: true} );
        };

    const handleOwnerChange = async (item) => {
        props.formChange({
            changed: true,
            owner_id: item.event.target.value,
            owner_uuid: item.element.props.personObj.uuid,
            owner_name: item.element.props.personObj.name 
        });
    };
    

    return (
        <div className="block">
            <div className="container-fluid">
                <div style={{ width: '100%' }}>
                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h4>Form Overview</h4>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group-sm col-lg-6">
                            <TextField
                                required 
                                label="Name"
                                name="name"
                                type="text"
                                onChange={ (event) => handleChange(event) }
                                defaultValue={props.data.name}
                                size="small"
                                fullWidth
                                error={props.errors && props.errors.name}
                                helperText={props.errors && props.errors.name ? props.errors.name : ""}
                            ></TextField>
                        </div>

                        <div className="form-group-sm col-lg-6">
                            <FormControl error={props.errors && props.errors.owner_id || false} fullWidth>
                                <InputLabel>Owner</InputLabel>
                                <Select
                                    label="responsible"
                                    name="owner_id"
                                    value={props.data.owner_id || ""}
                                    onChange={(evt, personObj) => {
                                        handleOwnerChange({
                                            event: evt,
                                            element: personObj
                                        });
                                    }}
                                    size="small"
                                >
                                    {props.personnel && props.personnel.map((item, key) => {
                                        return <MenuItem key={key} value={item.contact_id} personObj={item}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.owner_id || ""}</FormHelperText>
                            </FormControl>
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-12">
                            <TextField
                                label="Description"
                                name="description"
                                type="text"
                                value={props.data.description || ""}
                                multiline
                                minRows={2}
                                maxRows={5}
                                error={props.errors && props.errors.notes || false}
                                helperText={props.errors && props.errors.notes || ""}
                                onChange={(e) => handleChange(e) }
                                size="small"
                                fullWidth
                            ></TextField>
                        </div>
                    </div>

                    <Button
                //sx={buttonTitle()}
                onClick={() => props.handleSave()}
                variant="contained"
                startIcon={<SaveIcon/>}
            >Save</Button>



                </div>
            </div>
        </div>
    );

}
