import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppHeaderComponent, TitleControl } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../services/Permissions";
import ModuleFunctions from "../modules/ModuleFunctions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleButton from "../common/ModuleButton";
import ActiveIndex from "../modules/ActiveIndex";
import ArchivedIndex from "../modules/ArchivedIndex";
import Settings from "../modules/Settings";
import DraftIndex from "../modules/DraftIndex";
import { Button, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";


export default function Deals(props) {
  const [value, setValue] = useState(0);
  const [bus, setBus] = useState(false);

  console.log("props", props)

  const create = CanCreate("form-templates");
  const admin = CanModuleAdmin("form-templates");

  const tc = new TitleControl(); 
  let history = useHistory();

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    history.push({
      pathname: `/form-templates/${path}`
    });
  };

  const busChange = (data) => {
    setBus(data);
  };
  
  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { icon: "build", label: "active" },
    { icon: "update", label: "draft" },
    { icon: "archive", label: "archived" },
  ];

  if (admin) {
    tabs.push(
      { icon: "settings", label: "module settings" }
    )
  };
  
  const setTitle = () => {
    tc.setControlTitle((
        <div style={{ display: "flex", alignItems: "center",  gap: "1vw" }}>
          <Tooltip tip="Create a new template">
            <Button
                //sx={buttonTitle()}
                onClick={(e) => {handleLinkClick(e, "create")}}
                variant="contained"
                startIcon={<Add/>}
            >Add</Button>
            </Tooltip>
        </div>
    ), "FORM TEMPLATES")
  }

  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
    setTitle()
  }, []);

  return (
    <>

      <ModuleTabs tabs={tabs} value={value} setValue={setValue} handleChange={handleChange} />
      <div style={{ paddingTop: 0 }}>
      {value === 0 && 
          <ActiveIndex
            context={props.context}
            bus={bus}
          />}
        {value === 1 && 
          <DraftIndex
            context={props.context}
            bus={bus}
          />}
        {value === 2 && 
          <ArchivedIndex
            context={props.context}
            bus={bus}
          />}
        {value === 3 &&
          <Settings context={props.context} />}
      </div>
    </>
  );
}
