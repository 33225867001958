import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { ApiLoaderComponent, LoadingComponent, AppHeaderComponent, EntityFormApi } from "@unity/components";
import { CanDelete, CanReadAll, CanUpdate } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";
import ArchiveIcon from '@mui/icons-material/Archive';
import { Button, Tooltip } from "@mui/material";


export default function DraftIndex({ context, bus }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [personnel, setPersonnel] = useState(false)

  let permissions = context.auth.access["form-templates"]


  const edit = CanUpdate("form-templates");
  const readAll = CanReadAll("form-templates");

  const history = useHistory();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/form-templates/${path}/${params._id}`,
      state: { ...params }
    });
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };


  const getDraftForms = async () => {
    const res = await EntityFormApi.templateIndex( page + 1, pageSize, { draft: true } );

    if (res.ok) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };


  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleTemplateArchive = async (obj) => {

    const tempObj = {...obj}
    const tempId = tempObj._id

    tempObj.draft = false
    tempObj["archived"] = true
    delete tempObj._id
    
    setLoading({ status: true, data: 'Archiving Form, please wait...' });

    const res = await EntityFormApi.templateUpdate(tempId, {template: tempObj});

    if (res.success) {
      setLoading({ status: true, data: "Successfully Archived Form" });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setLoading({ status: true, data: 'Validation Errors!, Please Wait....' });
        setTimeout(() => {
          history.push({
            //pathname: `/form-templates/edit/${id}`,
            state: { hasErrors: res.errors }
          });
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      }
    }
    setFetchTrigger(fetchTrigger + 1);
  }
  // -----------------------------------------------------------------------------------

  const handleTemplateApprove = async (obj) => {

    const tempObj = {...obj}
    const tempId = tempObj._id

    tempObj.draft = false
    tempObj["approved"] = true
    delete tempObj._id

    
    setLoading({ status: true, data: 'Checking Template for approval request, please wait...' });

    const res = await EntityFormApi.templateUpdate(tempId, {template: tempObj});


    if (res.success) {
      setLoading({ status: true, data: "Successfully requested Form approval" });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setLoading({ status: true, data: 'Validation Errors!, Please Wait....' });
        setTimeout(() => {
          history.push({
            //pathname: `/form-templates/edit/${id}`,
            state: { hasErrors: res.errors }
          });
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      }
    }
    //setFetchTrigger(fetchTrigger + 1);
  }

  const convertDateToText = (date) => {
    try {
        const d = new Date(date);
        d.setHours(d.getHours() + 1);
        return new Intl.DateTimeFormat('en-GB', { dateStyle: 'long', timeStyle: 'medium', timeZone: 'Europe/London' }).format(d)
    } catch (e) {
        return "Invalid Date";
    }
}


  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "updated_at", headerName: "Last Update", flex: 1,
    valueGetter: (val) => {
      return(
      convertDateToText(val.row.updated_at)
      )
    }
  },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (

          <div>
            
            <ModuleButton
              btnIcon="edit"
              style={{
                background: "orange",
                color: "white",
              }}
              onClick={(event) => handleLinkClick(event, { ...cellValues.row, hasErrors: false }, "edit")}
              tip="Edit"
            /> 

{permissions.MA ? 
            <ModuleButton
              btnIcon="publish"
              style={{
                background: "green",
                color: "white",
                marginLeft: "10px"
              }}
              onClick={() => handleTemplateApprove(cellValues.row)}
              tip="Publish"
            /> 
            : null
}


  <Tooltip title={"archive"}>
  <Button
    variant="contained"
    component="label"
    style={{
      background: "red",
      color: "white",
      marginLeft: "10px"
    }}
    onClick={() => handleTemplateArchive( cellValues.row )}
  >
    <ArchiveIcon />
  </Button>
</Tooltip>            
            
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };


  useEffect(() => {
    getDraftForms();
    ModuleFunctions.getPersonnel({ personnelChange: setPersonnel });
  }, [ fetchTrigger ]);


  if (permissions.R && data) {
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={CanDelete("form-templates")}
          searchText={searchText}
          getRowId={ (data) => data._id }
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />
        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
