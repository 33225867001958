import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { ExportSDKApi} from "@unity/components";

export default function AddPdfId({open, setOpen, getPDF}) {

    const [pdf, setPdf] = React.useState({})
    
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleEdit = (info) => {
    const temp = {...pdf}
    temp[info.target.name] = info.target.value
    setPdf(temp)
  }

  const handleSave = async () => {
    const res = await ExportSDKApi.templateStore( {template: pdf} )

    if(res.ok){
    getPDF()
    handleClose()
    }
  }

  const handleClose = () => {
    setOpen(false)
    setPdf( {} )
  }


  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Connect a new PDF."}
        </DialogTitle>
        <DialogContent>

        <div className="form-row">

<div className="form-group col-lg-12">
                <TextField
                  style={{ marginRight: "2rem", width: "20rem" }}
                  label="Name"
                  name="name"
                  value={pdf["name"] || ""}
                  onChange={ (evt) => handleEdit(evt) }
                  size="small"
                />
                </div>
                <div className="form-group col-lg-12">

                <TextField
                  style={{ marginRight: "2rem", width: "20rem" }}
                  label="Description"
                  name="description"
                  value={pdf["description"] || ""}
                  onChange={ (evt) => handleEdit(evt) }
                  size="small"
                />
                </div>
                <div className="form-group col-lg-12">
                <TextField
                  style={{ marginRight: "2rem", width: "20rem" }}
                  label="ID"
                  name="external_id"
                  value={pdf["external_id"] || ""}
                  onChange={ (evt) => handleEdit(evt) }
                  size="small"
                />
                </div>
                </div>
        </DialogContent>
        <DialogActions>

                <Button
                    style={{ width: "10rem", backgroundColor: "blue" }}
                    onClick={() => handleSave()}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}