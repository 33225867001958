const VALIDATE_TYPE = "";
const CHAR_LIMIT_TEXT = 260;
const CHAR_LIMIT_TEXTBOX = 4000;
const MIN_NUMBER = 0;
const MAX_NUMBER = 255;


const textBuilder = (field) => {
    let text = field.validate_type || VALIDATE_TYPE
    let max = `max:${field.char_limit || CHAR_LIMIT_TEXT}`

    return `${text}${max.length > 0 ? "|" : ""}${max}`;
}

const textBoxBuilder = (field) => {
    return `max:${field.char_limit || CHAR_LIMIT_TEXTBOX}`;
}

const numberBuilder = (field) => {
    let min = `min:${field.min || MIN_NUMBER}`
    let max = `max:${field.max || MAX_NUMBER}`

    return `${min}${max.length > 0 ? "|" : ""}${max}`; 
}

const currencyBuilder = (field) => {
    let min = `min:${field.min || MIN_NUMBER}`
    let max = `max:${field.max || MAX_NUMBER}`

    return `${min}${max.length > 0 ? "|" : ""}${max}`; 
}

const checkboxBuilder = (field) => {
    return `boolean`
}

const optionsBuilder = (field) => {
    return ""
}

const imageBuilder = (field) => {
    return ""
}

const signitureBuilder = (field) => {
    return ""
}

const buttonBuilder = (field) => {
    return ""
}

const selectionTypes = {
    "text": textBuilder,
    "text_box": textBoxBuilder, 
    "number": numberBuilder, 
    "currency": currencyBuilder, 
    "options": optionsBuilder, 
    "checkbox": checkboxBuilder, 
    "image": imageBuilder, 
    "signiture": signitureBuilder, 
    "button": buttonBuilder
}

const ValidateStringBuilder = (field) => {
    if(typeof field != "object") return ""

    if(!field.type) return ""

    if(!selectionTypes[field.type]) return ""

    return selectionTypes[field.type](field)
}


export {ValidateStringBuilder, VALIDATE_TYPE, CHAR_LIMIT_TEXT, CHAR_LIMIT_TEXTBOX, MIN_NUMBER, MAX_NUMBER}