import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { LoadingComponent, ApiLoaderComponent, PersonnelApi, EntityFormApi, TitleControl, ExportSDKApi, MailerApi } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin, CanDelete } from "../services/Permissions";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import TemplateBuilder from "../modules/TemplateBuilder";
import TemplateEditSection from "../modules/TemplateEditSection";
import SaveIcon from '@mui/icons-material/Save';
import { ValidateStringBuilder } from "../common/ValidateStringBuilder";
import CloseIcon from '@mui/icons-material/Close';
import ReferenceButton from "../modules/referenceManager/referenceButton";
import AddPdfId from "../modules/AddPdfId";


export default function DealSelect(props) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null)
  const [personnel, setPersonnel] = useState(false)
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [pdfOptions, setPdfOptions] = useState(false)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [mailerId, setMailerId] = useState(false)
  const [referenceId, setReferenceId] = useState(false)
  const [open, setOpen] = useState(false)

  const tc = new TitleControl();

  let history = useHistory();

  const create = CanCreate("form-templates");
  const read = CanRead("form-templates");
  const edit = CanUpdate("form-templates");
  const admin = CanModuleAdmin("form-templates");


  const handleClickOpen = () => {
    setOpen(true)
  }

  const selectPdf = (p) => {
    const temp = {...data}
    temp["pdfTemplateId"] = p.target.value
    setData( temp )
  }

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const changeSelectedRow = (num) => {
    setSelectedRowIndex(num)
  }

  const editActionRow = (info) => {
    const temp = { ...data }
    temp.fields[selectedRowIndex] = info
    setData(temp)
  }

  const editOnChangeAction = (info, name) => {
    const tempData = { ...data }
    tempData.fields[selectedRowIndex][name].action = info.target.value
    setData(tempData)
  }

  const editOnchangeSettings = (info, name) => {
    const tempData = { ...data }
    if(!tempData.fields[selectedRowIndex][name].settings){
      tempData.fields[selectedRowIndex][name].settings = {}
    }
    tempData.fields[selectedRowIndex][name].settings[info.target.name] = info.target.value
    setData(tempData)
  }

  const editButtonChangeAction = (info, name) => {
    const tempData = { ...data }

    tempData.fields[selectedRowIndex][info.target.name] = info.target.value
    tempData.fields[selectedRowIndex]["settings"] = {}

    setData(tempData)
  }

  const editButtonchangeSettings = (info, name) => {
    const tempData = { ...data }

    if(!tempData.fields[selectedRowIndex].settings){
      tempData.fields[selectedRowIndex].settings = {}
    }
    tempData.fields[selectedRowIndex].settings[info.target.name] = info.target.value
    setData(tempData)
  }

  const editSelectedRow = (info) => {
    const tempData = { ...data }
    tempData.fields[selectedRowIndex][info.target.name] = info.target.value
    setData(tempData)
  }

  const replaceWhiteSpace = (info) => {
    var str = info.target.value
    const tempData = { ...data }
    var result = str.replace(/ /g, "_");

    tempData.fields[selectedRowIndex][info.target.name] = result
    setData(tempData) 
}

  const toggleRequired = (info, checked) => {
    const tempData = { ...data }
    tempData.fields[selectedRowIndex][info.target.name] = checked
    setData(tempData)
  }

  const changeRowOrder = (row, pos, i) => {
    const tempData = { ...data }
    let tempRow

    if (pos === "up" && i > 0) {
      tempRow = tempData.fields[i]
      tempData.fields[i] = tempData.fields[i - 1]
      tempData.fields[i - 1] = tempRow
    } else if (pos === "down" && i < data.fields.length - 1) {
      tempRow = tempData.fields[i]
      tempData.fields[i] = tempData.fields[i + 1]
      tempData.fields[i + 1] = tempRow
    }

    setData(tempData)
    setSelectedRowIndex(null)
  }

  const addNewRow = () => {
    const tempData = { ...data }
    data.fields.push(
      {
        "name": "",
        "type": null,
        "required": false,
        "onChangeOnce": null,
        "onChangeAlways": null,
        "managerOnly": false
      }
    )
    setData({ ...tempData })
  }

  const deleteRow = (i) => {
    const temp = { ...data };
    temp.fields.splice(i, 1)

    setData(temp)
    if (selectedRowIndex == i) {
      setSelectedRowIndex(null)
    }
  }


  const handleTemplateSave = async () => {

    setLoading({ status: true, data: "Saving template, Please Wait...." });
    const tempData = { ...data }
    const tempRule = {}

    tempData.fields.map((e) => {
      let validateString = ""
      let typeValidate = ""

      if (e.required) validateString += "required";
      else validateString += "nullable";

      typeValidate = ValidateStringBuilder(e)

      typeValidate = `${validateString}${typeValidate.length > 0 ? "|" : ""}${typeValidate}`

      tempRule[e.name] = typeValidate
    })
    tempData.rules = tempRule

    if (referenceId) {
      tempData["reference_id"] = referenceId
    } 
    
    if(tempData.pdfTemplateId){
      tempData.fields.map( (row) => {
        if(row.type === "checkbox" || row.type === "signature"){
          if(row["onChangeAlways"] != null){
            if(row["onChangeAlways"].action === "generatePDF" || row["onChangeAlways"].action === "generatePDFAndEmail"){
              row["onChangeAlways"].settings["pdfTemplateId"] = tempData.pdfTemplateId
            }

            if(typeof mailerId === "object" && row["onChangeAlways"].action === "sendEmail" || row["onChangeAlways"].action === "generatePDFAndEmail"){
              row["onChangeAlways"].settings["mailerId"] = mailerId.id
            }
          } else if(row["onChangeOnce"] != null){
            if(row.type === "checkbox" || row.type === "signature"){
              if(row["onChangeOnce"] != null){
                if(row["onChangeOnce"].action === "generatePDF" || row["onChangeOnce"].action === "generatePDFAndEmail"){
                  row["onChangeOnce"].settings["pdfTemplateId"] = tempData.pdfTemplateId
                }

                if(typeof mailerId === "object" && row["onChangeOnce"].action === "sendEmail" || row["onChangeOnce"].action === "generatePDFAndEmail"){
                  row["onChangeOnce"].settings["mailerId"] = mailerId.id
                }
          } } }
        } else if( row.type === "button"){
          if(row.action === "generatePDF" || row.action === "generatePDFAndEmail"){
          row.settings["pdfTemplateId"] = tempData.pdfTemplateId}

          if(typeof mailerId === "object" && row.action === "sendEmail" || row.action === "generatePDFAndEmail"){
            row.settings["mailerId"] = mailerId.id
          }
        }
      } )
    }


    const res = await EntityFormApi.templateUpdate(props.route.match.params.id, { template: tempData });

    if (res.success) {
      setLoading({ status: false });
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/form-templates/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const getPersonnel = async () => {
    const res = await PersonnelApi.getPersonnel()

    if (res.success) {
      setPersonnel(res.data)
    } else {
      setPersonnel([])
    }
  };

  const getTemplate = async () => {
    const res = await EntityFormApi.templateShow(props.route.match.params.id);
 
    if (res.ok) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const getPDF = async () => {
    const res = await ExportSDKApi.templateIndex(page, pageSize);

    if (res.ok) {
      setPdfOptions(res.data);
    } else {
      // the request has failed, load an empty array!
      setPdfOptions([]);
    }
  };

  const setTitle = () => {

    tc.setControlTitle((
      <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
        <Button
          //sx={buttonTitle()}
          onClick={() => history.push("/form-templates/index")}
          variant="contained"
          style={{ backgroundColor: "red" }}
          startIcon={<CloseIcon />}
        >Cancel</Button>
      </div>
    ), "EDIT")
  }

  const getMailSettings = async () => {
    const res = await MailerApi.getMailer();

    if (!res.data) {
      setMailerId({})
    } else {
      setMailerId(res.data);
    }
  };


  useEffect(() => {
    getMailSettings()
    getPDF()
    setTitle()
    getTemplate()
    getPersonnel()
  }, [fetchTrigger]);


  if (edit && data) {
    return (
      <div>

        <div className="block" >
          <div className="container-fluid">
            <div style={{ width: '100', display: "flex", justifyContent: "space-between" }}>

              <div>
                <TextField
                  style={{ marginRight: "2rem", width: "20rem" }}
                  label="Reference"
                  name="refernce"
                  value={referenceId || data.referenceId || ""}
                  size="small"
                />

                <ReferenceButton
                  context={props.context}
                  setReferenceId={setReferenceId}
                  referenceId={referenceId}
                />
              </div>

              {pdfOptions ?
                <div style={{ display: "flex" }}>
                  <FormControl error={errors && errors.sbu_id || false} fullWidth >
                    <InputLabel>PDF</InputLabel>
                    <Select
                      label="type"
                      name="type"
                      onChange={(evt) => selectPdf(evt)}
                      //onChange={(evt) => handleChange(evt)}
                      value={data["pdfTemplateId"] || ""}
                      //inputProps={{ readOnly: true }}
                      size="small"
                    >
                      {pdfOptions.data.map((item, key) => {
                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                      })}
                    </Select>
                    {/* <FormHelperText>{props.errors && props.errors.sbu_id || ""}</FormHelperText> */}
                  </FormControl>

                  <Tooltip title={"Add your PDF"}>
                  <Button
                    style={{ width: "10rem", backgroundColor: props.context.theme.top_menu.background, color: props.context.theme.top_menu.font, marginLeft: "2rem" }}
                    onClick={() => handleClickOpen()}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >Add</Button>
                  </Tooltip>
                </div> :
                <LoadingComponent color={props.context.theme.sidebar.background} />
              }


              {!data.approved || !data.archived && data.draft ?

                <Button
                  style={{ width: "10rem", backgroundColor: "orange" }}
                  onClick={() => handleTemplateSave()}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >Save</Button>

                : null}

            </div></div></div>
        <>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TemplateBuilder
                  data={data}
                  fields={data.fields}
                  changeSelectedRow={changeSelectedRow}
                  errors={errors}
                  addNewRow={addNewRow}
                  changeRowOrder={changeRowOrder}
                  deleteRow={deleteRow}
                  selectedRowIndex={selectedRowIndex}
                />
              </Grid>
              <Grid item xs={6}>
                <TemplateEditSection
                  data={data}
                  errors={errors}
                  editSelectedRow={editSelectedRow}
                  toggleRequired={toggleRequired}
                  context={props.context}
                  editActionRow={editActionRow}
                  selectedRowIndex={selectedRowIndex}
                  editOnChangeAction={editOnChangeAction}
                  editOnchangeSettings={editOnchangeSettings}
                  personnel={personnel}
                  templateId={data.pdfTemplateId}
                  editButtonChangeAction={editButtonChangeAction}
                  editButtonchangeSettings={editButtonchangeSettings}
                  mailerId={mailerId}
                  replaceWhiteSpace={replaceWhiteSpace}
                />
              </Grid>
            </Grid>
          </Box>

          <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />

        </>

        <AddPdfId
        open={open} 
        setOpen={setOpen}
        getPDF={getPDF}
        />
      </div>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
