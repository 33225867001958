import React from "react";
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, DialogActions, DialogContent, TextField, Tooltip } from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import DeleteIcon from '@mui/icons-material/Delete';

const TemplateBuilder = ({ data, fields, changeSelectedRow, addNewRow, changeRowOrder, deleteRow, selectedRowIndex }) => {


    return (
        <div className="block" >
            <div className="container-fluid">
                <div style={{ width: '100%', alignContent: "center" }}>

                    {fields != undefined && fields.length > 0 ?

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Label</strong></TableCell>

                                        <TableCell align="left"><strong>Action</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {fields && fields.map((row, key) => (

                                        <TableRow
                                            //key={row.description}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={selectedRowIndex == key ? {backgroundColor:"#F5FBFF"} : {}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.label ? row.label : row.name}
                                            </TableCell>


                                            <TableCell align='center' style={{ width: "360px" }}>

                                            <Tooltip title={"Edit field"}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ backgroundColor: "orange", marginLeft: "10px" }}
                                                    onClick={() => changeSelectedRow(key)}
                                                >
                                                    <EditIcon />
                                                </Button>
                                                </Tooltip>

                                                <Tooltip title={"Move field up"}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ backgroundColor: "#1976d2", marginLeft: "10px" }}
                                                    onClick={(evt) => changeRowOrder(evt, "up", key)}
                                                >
                                                    <ArrowCircleUpIcon />
                                                </Button>
                                                </Tooltip>

                                                <Tooltip title={"Move field down"}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ backgroundColor: "#1976d2", marginLeft: "10px" }}
                                                    onClick={(evt) => changeRowOrder(evt, "down", key)}
                                                >
                                                    <ArrowCircleDownIcon />
                                                </Button>
                                                </Tooltip>

                                                <Tooltip title={"Delete field"}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ backgroundColor: "red", marginLeft: "10px" }}
                                                    onClick={() => deleteRow(key)}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>



                        : <div style={{display:"flex", justifyContent:"center"}}><p>To get started add a new block</p></div>


                    }

                    <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"1rem" }}>
                    <Button
                        style={{ backgroundColor: "Blue", width: "3rem", height: "3rem", borderRadius: "30px" }}
                        onClick={() => addNewRow() }
                    >
                        <AddIcon style={{ color: "white" }} />
                    </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TemplateBuilder