import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { DealBuilderApi, ApiLoaderComponent, LoadingComponent, AppHeaderComponent, EntityFormApi } from "@unity/components";
import { CanDelete, CanReadAll, CanUpdate } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";


export default function ArchivedIndex({ context, bus }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [personnel, setPersonnel] = useState(false)


  let dealBuildPermission = context.auth.access["form-templates"]

  const edit = CanUpdate("form-templates");
  const readAll = CanReadAll("form-templates");

  const history = useHistory();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/form-templates/${path}/${params._id}`,
      state: { ...params }
    });
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };


  const getDraftForms = async () => {

    const res = await EntityFormApi.templateIndex( page + 1, pageSize, { archived: true } );

    if (res.ok) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const convertDateToText = (date) => {
    try {
        const d = new Date(date);
        d.setHours(d.getHours() + 1);
        return new Intl.DateTimeFormat('en-GB', { dateStyle: 'long', timeStyle: 'medium', timeZone: 'Europe/London' }).format(d)
    } catch (e) {
        return "Invalid Date";
    }
}



  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "updated_at", headerName: "Last Update", flex: 1,
    valueGetter: (val) => {
      return(
      convertDateToText(val.row.updated_at)
      )
    }
  },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (

          <div>
            { 
            <ModuleButton
            btnIcon="visibility"
            style={{
              background: "blue",
              color: "white"
            }}
              onClick={(event) => handleLinkClick(event, { ...cellValues.row, hasErrors: false }, "edit")}
              tip="View"
            /> 
            }       

          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };


  useEffect(() => {
    getDraftForms();
    ModuleFunctions.getPersonnel({ personnelChange: setPersonnel });
  }, [ fetchTrigger ]);


  if (dealBuildPermission.R && data) {
    return (
      <>

        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={CanDelete("form-templates")}
          searchText={searchText}
          getRowId={ (data) => data._id }
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />
        <ApiLoaderComponent status={loading.status} data={loading.data} />


      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
